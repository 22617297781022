import { GraphQLResolveInfo } from 'graphql';
import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type Asset = {
  __typename?: 'Asset';
  balance: Scalars['String']['output'];
  mint: Mint;
  unconfirmedBalance: Scalars['String']['output'];
  usdBalance: Scalars['String']['output'];
};

export enum Chain {
  Btc = 'BTC',
  Eth = 'ETH'
}

export type Customer = {
  __typename?: 'Customer';
  createdAt: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  organizationExternalId: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
};

export enum CustomerTransactionType {
  Deposit = 'DEPOSIT',
  GasReceived = 'GAS_RECEIVED',
  GasUsed = 'GAS_USED',
  InternalTransfer = 'INTERNAL_TRANSFER'
}

export type CustomerTransactions = {
  __typename?: 'CustomerTransactions';
  amount: Scalars['String']['output'];
  confirmedAt: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  customerId: Scalars['String']['output'];
  failedReason?: Maybe<Scalars['String']['output']>;
  fromAddress: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  mint: Mint;
  status: TransactionStatus;
  toAddress: Scalars['String']['output'];
  transactionReference: Scalars['String']['output'];
  txHash: Scalars['String']['output'];
  type: CustomerTransactionType;
  uiAmount: Scalars['String']['output'];
  usdAmount: Scalars['String']['output'];
};

export type CustomerTransactionsPagination = {
  __typename?: 'CustomerTransactionsPagination';
  totalCount: Scalars['Int']['output'];
  transactions: Array<CustomerTransactions>;
};

export type CustomerWallet = {
  __typename?: 'CustomerWallet';
  address: Scalars['String']['output'];
  assets: Array<Asset>;
  chain?: Maybe<Scalars['String']['output']>;
  totalBalanceUSD: Scalars['String']['output'];
};

export type CustomerWithdrawalPagination = {
  __typename?: 'CustomerWithdrawalPagination';
  totalCount: Scalars['Int']['output'];
  withdrawals: Array<MainWalletTransaction>;
};

export type GasTransaction = {
  __typename?: 'GasTransaction';
  amount: Scalars['String']['output'];
  confirmedAt: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  fromAddress: Scalars['String']['output'];
  gasWalletId: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  mint: Mint;
  status: TransactionStatus;
  toAddress: Scalars['String']['output'];
  transactionReference: Scalars['String']['output'];
  txHash: Scalars['String']['output'];
  type: GasTransactionType;
  uiAmount: Scalars['String']['output'];
  usdAmount: Scalars['String']['output'];
};

export enum GasTransactionType {
  BundleGas = 'BUNDLE_GAS',
  CustomerGasSent = 'CUSTOMER_GAS_SENT',
  Deposit = 'DEPOSIT',
  GasRefund = 'GAS_REFUND',
  GasUsed = 'GAS_USED',
  MainGasSent = 'MAIN_GAS_SENT',
  RevenueGasSent = 'REVENUE_GAS_SENT'
}

export type GasTransactionsPagination = {
  __typename?: 'GasTransactionsPagination';
  totalCount: Scalars['Int']['output'];
  transactions: Array<GasTransaction>;
};

export type GasWallet = {
  __typename?: 'GasWallet';
  address: Scalars['String']['output'];
  chain?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
};

export type InternalTransferThreshold = {
  __typename?: 'InternalTransferThreshold';
  amountInUSD: Scalars['Float']['output'];
  chain?: Maybe<Chain>;
  id: Scalars['Int']['output'];
};

export type MainWalletFailedTransaction = {
  __typename?: 'MainWalletFailedTransaction';
  reason?: Maybe<Scalars['String']['output']>;
  retriedTransactionId?: Maybe<Scalars['Int']['output']>;
};

export type MainWalletTransaction = {
  __typename?: 'MainWalletTransaction';
  amount: Scalars['String']['output'];
  confirmedAt: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  failedReason?: Maybe<Scalars['String']['output']>;
  fromAddress: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  mainWalletId: Scalars['Int']['output'];
  mint: Mint;
  status: TransactionStatus;
  toAddress: Scalars['String']['output'];
  transactionReference: Scalars['String']['output'];
  txHash: Scalars['String']['output'];
  type: MainWalletTransactionType;
  uiAmount: Scalars['String']['output'];
  usdAmount: Scalars['String']['output'];
};

export enum MainWalletTransactionType {
  CustomerWithdrawal = 'CUSTOMER_WITHDRAWAL',
  Deposit = 'DEPOSIT',
  Fee = 'FEE',
  GasReceived = 'GAS_RECEIVED',
  GasUsed = 'GAS_USED',
  InternalTransfer = 'INTERNAL_TRANSFER',
  MainWithdrawal = 'MAIN_WITHDRAWAL'
}

export type MainWalletTransactionsPagination = {
  __typename?: 'MainWalletTransactionsPagination';
  totalCount: Scalars['Int']['output'];
  transactions: Array<MainWalletTransaction>;
};

export type Mint = {
  __typename?: 'Mint';
  address?: Maybe<Scalars['String']['output']>;
  chain: Scalars['String']['output'];
  decimals: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  img?: Maybe<Scalars['String']['output']>;
  isNative: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  symbol: Scalars['String']['output'];
  usdPrice: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  approveTransaction: Scalars['String']['output'];
  logout: Scalars['Boolean']['output'];
  requestTwoFactorAuth: Scalars['String']['output'];
  resetMyPassword: User;
  retryTransaction: Scalars['String']['output'];
  rollApiKey: Scalars['String']['output'];
  setupTwoFactorAuth: User;
  updateEmail: User;
  updateMinimumThreshold: Scalars['Boolean']['output'];
  updatePassword: User;
  updateUsername: User;
  withdraw: Scalars['String']['output'];
};


export type MutationApproveTransactionArgs = {
  transactionId: Scalars['Int']['input'];
};


export type MutationRetryTransactionArgs = {
  transactionId: Scalars['Int']['input'];
};


export type MutationRollApiKeyArgs = {
  organizationId: Scalars['String']['input'];
};


export type MutationSetupTwoFactorAuthArgs = {
  secret: Scalars['String']['input'];
};


export type MutationUpdateEmailArgs = {
  email: Scalars['String']['input'];
};


export type MutationUpdateMinimumThresholdArgs = {
  amountInUSD: Scalars['Float']['input'];
  chain: Chain;
  organizationId: Scalars['String']['input'];
};


export type MutationUpdatePasswordArgs = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};


export type MutationUpdateUsernameArgs = {
  username: Scalars['String']['input'];
};


export type MutationWithdrawArgs = {
  amount: Scalars['String']['input'];
  mintId: Scalars['Int']['input'];
  whitelistId: Scalars['Int']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['String']['output'];
  internalTransferThreshold: Array<InternalTransferThreshold>;
  name: Scalars['String']['output'];
  webhookAppId?: Maybe<Scalars['String']['output']>;
  whitelist: Array<Whitelist>;
};

export type OrganizationWallet = {
  __typename?: 'OrganizationWallet';
  address: Scalars['String']['output'];
  assets: Array<Asset>;
  chain?: Maybe<Scalars['String']['output']>;
  totalBalanceUSD: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  getCustomer: Customer;
  getCustomerTransactions: CustomerTransactionsPagination;
  getCustomerWallets?: Maybe<Array<CustomerWallet>>;
  getCustomerWithdrawals: CustomerWithdrawalPagination;
  getCustomers: Array<Customer>;
  getEthGasOwed: Scalars['String']['output'];
  getGasTransactions: GasTransactionsPagination;
  getGasWallets: Array<GasWallet>;
  getMainWalletTransactions: MainWalletTransactionsPagination;
  getMints: Array<Mint>;
  getOrganization: Organization;
  getTransactionsToApprove: MainWalletTransactionsPagination;
  getUser: User;
  getUsersOfOrganization: GetUsersOfOrganization;
  getWallets: Array<OrganizationWallet>;
  getWebhookIFrameUrl: Scalars['String']['output'];
  getWhitelist: Array<Whitelist>;
  getWithdrawThreshold: WithdrawThreshold;
};


export type QueryGetCustomerArgs = {
  customerId: Scalars['String']['input'];
};


export type QueryGetCustomerTransactionsArgs = {
  customerId: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
};


export type QueryGetCustomerWalletsArgs = {
  customerId: Scalars['String']['input'];
};


export type QueryGetCustomerWithdrawalsArgs = {
  customerId: Scalars['String']['input'];
  limit: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
};


export type QueryGetCustomersArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetEthGasOwedArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetGasTransactionsArgs = {
  limit: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
};


export type QueryGetGasWalletsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetMainWalletTransactionsArgs = {
  limit: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
};


export type QueryGetOrganizationArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetTransactionsToApproveArgs = {
  limit: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
};


export type QueryGetUsersOfOrganizationArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryGetWalletsArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetWebhookIFrameUrlArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetWhitelistArgs = {
  organizationId: Scalars['String']['input'];
};


export type QueryGetWithdrawThresholdArgs = {
  organizationId: Scalars['String']['input'];
};

export enum TransactionStatus {
  AwaitingConfirmation = 'AWAITING_CONFIRMATION',
  Confirmed = 'CONFIRMED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type User = {
  __typename?: 'User';
  email: Scalars['String']['output'];
  has2fa: Scalars['Boolean']['output'];
  organizationId: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type Whitelist = {
  __typename?: 'Whitelist';
  address: Scalars['String']['output'];
  chain: Chain;
  id: Scalars['Int']['output'];
};

export type WithdrawThreshold = {
  __typename?: 'WithdrawThreshold';
  customers?: Maybe<Scalars['Float']['output']>;
  main?: Maybe<Scalars['Float']['output']>;
};

export type GetUsersOfOrganization = {
  __typename?: 'getUsersOfOrganization';
  cursor: Scalars['String']['output'];
  hasMore: Scalars['Boolean']['output'];
  totalCount: Scalars['Int']['output'];
  users: Array<User>;
};

export type UserFragment = { __typename?: 'User', userId: string, username: string, email: string, has2fa: boolean, organizationId: string };

export type AssetFragment = { __typename?: 'Asset', unconfirmedBalance: string, balance: string, usdBalance: string, mint: { __typename?: 'Mint', id: number, name: string, chain: string, img?: string | null, isNative: boolean, symbol: string, decimals: number, address?: string | null, usdPrice: string } };

export type MintFragment = { __typename?: 'Mint', id: number, name: string, chain: string, img?: string | null, isNative: boolean, symbol: string, decimals: number, address?: string | null, usdPrice: string };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', userId: string, username: string, email: string, has2fa: boolean, organizationId: string } };

export type GetMintsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMintsQuery = { __typename?: 'Query', getMints: Array<{ __typename?: 'Mint', id: number, name: string, chain: string, img?: string | null, isNative: boolean, symbol: string, decimals: number, address?: string | null, usdPrice: string }> };

export type GetWalletsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetWalletsQuery = { __typename?: 'Query', getWallets: Array<{ __typename?: 'OrganizationWallet', address: string, chain?: string | null, totalBalanceUSD: string, assets: Array<{ __typename?: 'Asset', unconfirmedBalance: string, balance: string, usdBalance: string, mint: { __typename?: 'Mint', id: number, name: string, chain: string, img?: string | null, isNative: boolean, symbol: string, decimals: number, address?: string | null, usdPrice: string } }> }> };

export type GetGasWalletsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetGasWalletsQuery = { __typename?: 'Query', getGasWallets: Array<{ __typename?: 'GasWallet', id: number, address: string, chain?: string | null }> };

export type GetEthGasOwedQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetEthGasOwedQuery = { __typename?: 'Query', getEthGasOwed: string };

export type GetOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetOrganizationQuery = { __typename?: 'Query', getOrganization: { __typename?: 'Organization', id: string, name: string, webhookAppId?: string | null, whitelist: Array<{ __typename?: 'Whitelist', id: number, chain: Chain, address: string }>, internalTransferThreshold: Array<{ __typename?: 'InternalTransferThreshold', id: number, amountInUSD: number, chain?: Chain | null }> } };

export type GetUsersOfOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  cursor?: InputMaybe<Scalars['String']['input']>;
  limit: Scalars['Int']['input'];
}>;


export type GetUsersOfOrganizationQuery = { __typename?: 'Query', getUsersOfOrganization: { __typename?: 'getUsersOfOrganization', hasMore: boolean, cursor: string, totalCount: number, users: Array<{ __typename?: 'User', userId: string, username: string, email: string, has2fa: boolean, organizationId: string }> } };

export type GetWithdrawThresholdQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetWithdrawThresholdQuery = { __typename?: 'Query', getWithdrawThreshold: { __typename?: 'WithdrawThreshold', customers?: number | null, main?: number | null } };

export type GetTransactionsToApproveQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type GetTransactionsToApproveQuery = { __typename?: 'Query', getTransactionsToApprove: { __typename?: 'MainWalletTransactionsPagination', totalCount: number, transactions: Array<{ __typename?: 'MainWalletTransaction', id: number, mainWalletId: number, txHash: string, amount: string, uiAmount: string, usdAmount: string, fromAddress: string, toAddress: string, transactionReference: string, createdAt: string, confirmedAt: string, type: MainWalletTransactionType, status: TransactionStatus, mint: { __typename?: 'Mint', id: number, name: string, chain: string, img?: string | null, isNative: boolean, symbol: string, decimals: number, address?: string | null, usdPrice: string } }> } };

export type GetWhitelistQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetWhitelistQuery = { __typename?: 'Query', getWhitelist: Array<{ __typename?: 'Whitelist', id: number, chain: Chain, address: string }> };

export type GetWebhookIFrameUrlQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetWebhookIFrameUrlQuery = { __typename?: 'Query', getWebhookIFrameUrl: string };

export type GetCustomersQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type GetCustomersQuery = { __typename?: 'Query', getCustomers: Array<{ __typename?: 'Customer', id: string, createdAt: string, isActive: boolean, organizationId: string, organizationExternalId: string }> };

export type GetCustomerQueryVariables = Exact<{
  customerId: Scalars['String']['input'];
}>;


export type GetCustomerQuery = { __typename?: 'Query', getCustomer: { __typename?: 'Customer', id: string, createdAt: string, isActive: boolean, organizationId: string, organizationExternalId: string } };

export type GetCustomerWalletsQueryVariables = Exact<{
  customerId: Scalars['String']['input'];
}>;


export type GetCustomerWalletsQuery = { __typename?: 'Query', getCustomerWallets?: Array<{ __typename?: 'CustomerWallet', address: string, chain?: string | null, totalBalanceUSD: string, assets: Array<{ __typename?: 'Asset', unconfirmedBalance: string, balance: string, usdBalance: string, mint: { __typename?: 'Mint', id: number, name: string, chain: string, img?: string | null, isNative: boolean, symbol: string, decimals: number, address?: string | null, usdPrice: string } }> }> | null };

export type GetCustomerTransactionsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type GetCustomerTransactionsQuery = { __typename?: 'Query', getCustomerTransactions: { __typename?: 'CustomerTransactionsPagination', totalCount: number, transactions: Array<{ __typename?: 'CustomerTransactions', id: number, customerId: string, txHash: string, amount: string, uiAmount: string, usdAmount: string, failedReason?: string | null, fromAddress: string, toAddress: string, transactionReference: string, createdAt: string, confirmedAt: string, type: CustomerTransactionType, status: TransactionStatus, mint: { __typename?: 'Mint', id: number, name: string, chain: string, img?: string | null, isNative: boolean, symbol: string, decimals: number, address?: string | null, usdPrice: string } }> } };

export type GetCustomerWithdrawalsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  customerId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type GetCustomerWithdrawalsQuery = { __typename?: 'Query', getCustomerWithdrawals: { __typename?: 'CustomerWithdrawalPagination', totalCount: number, withdrawals: Array<{ __typename?: 'MainWalletTransaction', id: number, mainWalletId: number, txHash: string, amount: string, uiAmount: string, usdAmount: string, fromAddress: string, toAddress: string, transactionReference: string, createdAt: string, confirmedAt: string, type: MainWalletTransactionType, status: TransactionStatus, mint: { __typename?: 'Mint', id: number, name: string, chain: string, img?: string | null, isNative: boolean, symbol: string, decimals: number, address?: string | null, usdPrice: string } }> } };

export type GetMainWalletTransactionsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type GetMainWalletTransactionsQuery = { __typename?: 'Query', getMainWalletTransactions: { __typename?: 'MainWalletTransactionsPagination', totalCount: number, transactions: Array<{ __typename?: 'MainWalletTransaction', id: number, mainWalletId: number, txHash: string, amount: string, uiAmount: string, usdAmount: string, failedReason?: string | null, fromAddress: string, toAddress: string, transactionReference: string, createdAt: string, confirmedAt: string, type: MainWalletTransactionType, status: TransactionStatus, mint: { __typename?: 'Mint', id: number, name: string, chain: string, img?: string | null, isNative: boolean, symbol: string, decimals: number, address?: string | null, usdPrice: string } }> } };

export type GetGasTransactionsQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  page: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;


export type GetGasTransactionsQuery = { __typename?: 'Query', getGasTransactions: { __typename?: 'GasTransactionsPagination', totalCount: number, transactions: Array<{ __typename?: 'GasTransaction', id: number, gasWalletId: number, txHash: string, amount: string, uiAmount: string, usdAmount: string, fromAddress: string, toAddress: string, transactionReference: string, createdAt: string, confirmedAt: string, type: GasTransactionType, status: TransactionStatus, mint: { __typename?: 'Mint', id: number, name: string, chain: string, img?: string | null, isNative: boolean, symbol: string, decimals: number, address?: string | null, usdPrice: string } }> } };

export type RollApiKeyMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
}>;


export type RollApiKeyMutation = { __typename?: 'Mutation', rollApiKey: string };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type UpdateUsernameMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type UpdateUsernameMutation = { __typename?: 'Mutation', updateUsername: { __typename?: 'User', userId: string, username: string, email: string, has2fa: boolean, organizationId: string } };

export type UpdateEmailMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type UpdateEmailMutation = { __typename?: 'Mutation', updateEmail: { __typename?: 'User', userId: string, username: string, email: string, has2fa: boolean, organizationId: string } };

export type UpdatePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword: { __typename?: 'User', userId: string, username: string, email: string } };

export type ResetMyPasswordMutationVariables = Exact<{ [key: string]: never; }>;


export type ResetMyPasswordMutation = { __typename?: 'Mutation', resetMyPassword: { __typename?: 'User', userId: string, username: string, email: string, has2fa: boolean, organizationId: string } };

export type RequestTwoFactorAuthMutationVariables = Exact<{ [key: string]: never; }>;


export type RequestTwoFactorAuthMutation = { __typename?: 'Mutation', requestTwoFactorAuth: string };

export type SetupTwoFactorAuthMutationVariables = Exact<{
  secret: Scalars['String']['input'];
}>;


export type SetupTwoFactorAuthMutation = { __typename?: 'Mutation', setupTwoFactorAuth: { __typename?: 'User', userId: string, username: string, email: string, has2fa: boolean, organizationId: string } };

export type WithdrawMutationVariables = Exact<{
  amount: Scalars['String']['input'];
  whitelistId: Scalars['Int']['input'];
  mintId: Scalars['Int']['input'];
}>;


export type WithdrawMutation = { __typename?: 'Mutation', withdraw: string };

export type UpdateMinimumThresholdMutationVariables = Exact<{
  organizationId: Scalars['String']['input'];
  amountInUSD: Scalars['Float']['input'];
  chain: Chain;
}>;


export type UpdateMinimumThresholdMutation = { __typename?: 'Mutation', updateMinimumThreshold: boolean };

export type ApproveTransactionMutationVariables = Exact<{
  transactionId: Scalars['Int']['input'];
}>;


export type ApproveTransactionMutation = { __typename?: 'Mutation', approveTransaction: string };

export type RetryTransactionMutationVariables = Exact<{
  transactionId: Scalars['Int']['input'];
}>;


export type RetryTransactionMutation = { __typename?: 'Mutation', retryTransaction: string };

export const UserFragmentDoc = gql`
    fragment User on User {
  userId
  username
  email
  has2fa
  organizationId
}
    `;
export const MintFragmentDoc = gql`
    fragment Mint on Mint {
  id
  name
  chain
  img
  isNative
  symbol
  decimals
  address
  usdPrice
}
    `;
export const AssetFragmentDoc = gql`
    fragment Asset on Asset {
  unconfirmedBalance
  balance
  usdBalance
  mint {
    ...Mint
  }
}
    ${MintFragmentDoc}`;
export const GetUserDocument = gql`
    query getUser {
  getUser {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useGetUserQuery(options?: Omit<Urql.UseQueryArgs<GetUserQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserQuery, GetUserQueryVariables>({ query: GetUserDocument, ...options });
};
export const GetMintsDocument = gql`
    query getMints {
  getMints {
    ...Mint
  }
}
    ${MintFragmentDoc}`;

export function useGetMintsQuery(options?: Omit<Urql.UseQueryArgs<GetMintsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMintsQuery, GetMintsQueryVariables>({ query: GetMintsDocument, ...options });
};
export const GetWalletsDocument = gql`
    query getWallets($organizationId: String!) {
  getWallets(organizationId: $organizationId) {
    address
    chain
    totalBalanceUSD
    assets {
      ...Asset
    }
  }
}
    ${AssetFragmentDoc}`;

export function useGetWalletsQuery(options: Omit<Urql.UseQueryArgs<GetWalletsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWalletsQuery, GetWalletsQueryVariables>({ query: GetWalletsDocument, ...options });
};
export const GetGasWalletsDocument = gql`
    query getGasWallets($organizationId: String!) {
  getGasWallets(organizationId: $organizationId) {
    id
    address
    chain
  }
}
    `;

export function useGetGasWalletsQuery(options: Omit<Urql.UseQueryArgs<GetGasWalletsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetGasWalletsQuery, GetGasWalletsQueryVariables>({ query: GetGasWalletsDocument, ...options });
};
export const GetEthGasOwedDocument = gql`
    query getEthGasOwed($organizationId: String!) {
  getEthGasOwed(organizationId: $organizationId)
}
    `;

export function useGetEthGasOwedQuery(options: Omit<Urql.UseQueryArgs<GetEthGasOwedQueryVariables>, 'query'>) {
  return Urql.useQuery<GetEthGasOwedQuery, GetEthGasOwedQueryVariables>({ query: GetEthGasOwedDocument, ...options });
};
export const GetOrganizationDocument = gql`
    query getOrganization($organizationId: String!) {
  getOrganization(organizationId: $organizationId) {
    id
    name
    webhookAppId
    whitelist {
      id
      chain
      address
    }
    internalTransferThreshold {
      id
      amountInUSD
      chain
    }
  }
}
    `;

export function useGetOrganizationQuery(options: Omit<Urql.UseQueryArgs<GetOrganizationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>({ query: GetOrganizationDocument, ...options });
};
export const GetUsersOfOrganizationDocument = gql`
    query getUsersOfOrganization($organizationId: String!, $cursor: String, $limit: Int!) {
  getUsersOfOrganization(
    organizationId: $organizationId
    cursor: $cursor
    limit: $limit
  ) {
    users {
      ...User
    }
    hasMore
    cursor
    totalCount
  }
}
    ${UserFragmentDoc}`;

export function useGetUsersOfOrganizationQuery(options: Omit<Urql.UseQueryArgs<GetUsersOfOrganizationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUsersOfOrganizationQuery, GetUsersOfOrganizationQueryVariables>({ query: GetUsersOfOrganizationDocument, ...options });
};
export const GetWithdrawThresholdDocument = gql`
    query getWithdrawThreshold($organizationId: String!) {
  getWithdrawThreshold(organizationId: $organizationId) {
    customers
    main
  }
}
    `;

export function useGetWithdrawThresholdQuery(options: Omit<Urql.UseQueryArgs<GetWithdrawThresholdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWithdrawThresholdQuery, GetWithdrawThresholdQueryVariables>({ query: GetWithdrawThresholdDocument, ...options });
};
export const GetTransactionsToApproveDocument = gql`
    query getTransactionsToApprove($organizationId: String!, $page: Int!, $limit: Int!) {
  getTransactionsToApprove(
    organizationId: $organizationId
    page: $page
    limit: $limit
  ) {
    transactions {
      id
      mainWalletId
      txHash
      amount
      uiAmount
      usdAmount
      fromAddress
      toAddress
      mint {
        ...Mint
      }
      transactionReference
      createdAt
      confirmedAt
      type
      status
    }
    totalCount
  }
}
    ${MintFragmentDoc}`;

export function useGetTransactionsToApproveQuery(options: Omit<Urql.UseQueryArgs<GetTransactionsToApproveQueryVariables>, 'query'>) {
  return Urql.useQuery<GetTransactionsToApproveQuery, GetTransactionsToApproveQueryVariables>({ query: GetTransactionsToApproveDocument, ...options });
};
export const GetWhitelistDocument = gql`
    query getWhitelist($organizationId: String!) {
  getWhitelist(organizationId: $organizationId) {
    id
    chain
    address
  }
}
    `;

export function useGetWhitelistQuery(options: Omit<Urql.UseQueryArgs<GetWhitelistQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWhitelistQuery, GetWhitelistQueryVariables>({ query: GetWhitelistDocument, ...options });
};
export const GetWebhookIFrameUrlDocument = gql`
    query getWebhookIFrameUrl($organizationId: String!) {
  getWebhookIFrameUrl(organizationId: $organizationId)
}
    `;

export function useGetWebhookIFrameUrlQuery(options: Omit<Urql.UseQueryArgs<GetWebhookIFrameUrlQueryVariables>, 'query'>) {
  return Urql.useQuery<GetWebhookIFrameUrlQuery, GetWebhookIFrameUrlQueryVariables>({ query: GetWebhookIFrameUrlDocument, ...options });
};
export const GetCustomersDocument = gql`
    query getCustomers($organizationId: String!) {
  getCustomers(organizationId: $organizationId) {
    id
    createdAt
    isActive
    organizationId
    organizationExternalId
  }
}
    `;

export function useGetCustomersQuery(options: Omit<Urql.UseQueryArgs<GetCustomersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCustomersQuery, GetCustomersQueryVariables>({ query: GetCustomersDocument, ...options });
};
export const GetCustomerDocument = gql`
    query getCustomer($customerId: String!) {
  getCustomer(customerId: $customerId) {
    id
    createdAt
    isActive
    organizationId
    organizationExternalId
  }
}
    `;

export function useGetCustomerQuery(options: Omit<Urql.UseQueryArgs<GetCustomerQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCustomerQuery, GetCustomerQueryVariables>({ query: GetCustomerDocument, ...options });
};
export const GetCustomerWalletsDocument = gql`
    query getCustomerWallets($customerId: String!) {
  getCustomerWallets(customerId: $customerId) {
    address
    chain
    totalBalanceUSD
    assets {
      ...Asset
    }
  }
}
    ${AssetFragmentDoc}`;

export function useGetCustomerWalletsQuery(options: Omit<Urql.UseQueryArgs<GetCustomerWalletsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCustomerWalletsQuery, GetCustomerWalletsQueryVariables>({ query: GetCustomerWalletsDocument, ...options });
};
export const GetCustomerTransactionsDocument = gql`
    query getCustomerTransactions($organizationId: String!, $customerId: String!, $page: Int!, $limit: Int!) {
  getCustomerTransactions(
    organizationId: $organizationId
    customerId: $customerId
    page: $page
    limit: $limit
  ) {
    transactions {
      id
      customerId
      txHash
      amount
      uiAmount
      usdAmount
      failedReason
      fromAddress
      toAddress
      mint {
        ...Mint
      }
      transactionReference
      createdAt
      confirmedAt
      type
      status
    }
    totalCount
  }
}
    ${MintFragmentDoc}`;

export function useGetCustomerTransactionsQuery(options: Omit<Urql.UseQueryArgs<GetCustomerTransactionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCustomerTransactionsQuery, GetCustomerTransactionsQueryVariables>({ query: GetCustomerTransactionsDocument, ...options });
};
export const GetCustomerWithdrawalsDocument = gql`
    query getCustomerWithdrawals($organizationId: String!, $customerId: String!, $page: Int!, $limit: Int!) {
  getCustomerWithdrawals(
    organizationId: $organizationId
    customerId: $customerId
    page: $page
    limit: $limit
  ) {
    withdrawals {
      id
      mainWalletId
      txHash
      amount
      uiAmount
      usdAmount
      fromAddress
      toAddress
      mint {
        ...Mint
      }
      transactionReference
      createdAt
      confirmedAt
      type
      status
    }
    totalCount
  }
}
    ${MintFragmentDoc}`;

export function useGetCustomerWithdrawalsQuery(options: Omit<Urql.UseQueryArgs<GetCustomerWithdrawalsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCustomerWithdrawalsQuery, GetCustomerWithdrawalsQueryVariables>({ query: GetCustomerWithdrawalsDocument, ...options });
};
export const GetMainWalletTransactionsDocument = gql`
    query getMainWalletTransactions($organizationId: String!, $page: Int!, $limit: Int!) {
  getMainWalletTransactions(
    organizationId: $organizationId
    page: $page
    limit: $limit
  ) {
    transactions {
      id
      mainWalletId
      txHash
      amount
      uiAmount
      usdAmount
      failedReason
      fromAddress
      toAddress
      mint {
        ...Mint
      }
      transactionReference
      createdAt
      confirmedAt
      type
      status
    }
    totalCount
  }
}
    ${MintFragmentDoc}`;

export function useGetMainWalletTransactionsQuery(options: Omit<Urql.UseQueryArgs<GetMainWalletTransactionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMainWalletTransactionsQuery, GetMainWalletTransactionsQueryVariables>({ query: GetMainWalletTransactionsDocument, ...options });
};
export const GetGasTransactionsDocument = gql`
    query getGasTransactions($organizationId: String!, $page: Int!, $limit: Int!) {
  getGasTransactions(organizationId: $organizationId, page: $page, limit: $limit) {
    transactions {
      id
      gasWalletId
      txHash
      amount
      uiAmount
      usdAmount
      fromAddress
      toAddress
      mint {
        ...Mint
      }
      transactionReference
      createdAt
      confirmedAt
      type
      status
    }
    totalCount
  }
}
    ${MintFragmentDoc}`;

export function useGetGasTransactionsQuery(options: Omit<Urql.UseQueryArgs<GetGasTransactionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetGasTransactionsQuery, GetGasTransactionsQueryVariables>({ query: GetGasTransactionsDocument, ...options });
};
export const RollApiKeyDocument = gql`
    mutation rollApiKey($organizationId: String!) {
  rollApiKey(organizationId: $organizationId)
}
    `;

export function useRollApiKeyMutation() {
  return Urql.useMutation<RollApiKeyMutation, RollApiKeyMutationVariables>(RollApiKeyDocument);
};
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;

export function useLogoutMutation() {
  return Urql.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument);
};
export const UpdateUsernameDocument = gql`
    mutation updateUsername($username: String!) {
  updateUsername(username: $username) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useUpdateUsernameMutation() {
  return Urql.useMutation<UpdateUsernameMutation, UpdateUsernameMutationVariables>(UpdateUsernameDocument);
};
export const UpdateEmailDocument = gql`
    mutation updateEmail($email: String!) {
  updateEmail(email: $email) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useUpdateEmailMutation() {
  return Urql.useMutation<UpdateEmailMutation, UpdateEmailMutationVariables>(UpdateEmailDocument);
};
export const UpdatePasswordDocument = gql`
    mutation updatePassword($oldPassword: String!, $newPassword: String!) {
  updatePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
    userId
    username
    email
  }
}
    `;

export function useUpdatePasswordMutation() {
  return Urql.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument);
};
export const ResetMyPasswordDocument = gql`
    mutation resetMyPassword {
  resetMyPassword {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useResetMyPasswordMutation() {
  return Urql.useMutation<ResetMyPasswordMutation, ResetMyPasswordMutationVariables>(ResetMyPasswordDocument);
};
export const RequestTwoFactorAuthDocument = gql`
    mutation requestTwoFactorAuth {
  requestTwoFactorAuth
}
    `;

export function useRequestTwoFactorAuthMutation() {
  return Urql.useMutation<RequestTwoFactorAuthMutation, RequestTwoFactorAuthMutationVariables>(RequestTwoFactorAuthDocument);
};
export const SetupTwoFactorAuthDocument = gql`
    mutation setupTwoFactorAuth($secret: String!) {
  setupTwoFactorAuth(secret: $secret) {
    ...User
  }
}
    ${UserFragmentDoc}`;

export function useSetupTwoFactorAuthMutation() {
  return Urql.useMutation<SetupTwoFactorAuthMutation, SetupTwoFactorAuthMutationVariables>(SetupTwoFactorAuthDocument);
};
export const WithdrawDocument = gql`
    mutation withdraw($amount: String!, $whitelistId: Int!, $mintId: Int!) {
  withdraw(amount: $amount, whitelistId: $whitelistId, mintId: $mintId)
}
    `;

export function useWithdrawMutation() {
  return Urql.useMutation<WithdrawMutation, WithdrawMutationVariables>(WithdrawDocument);
};
export const UpdateMinimumThresholdDocument = gql`
    mutation updateMinimumThreshold($organizationId: String!, $amountInUSD: Float!, $chain: Chain!) {
  updateMinimumThreshold(
    organizationId: $organizationId
    amountInUSD: $amountInUSD
    chain: $chain
  )
}
    `;

export function useUpdateMinimumThresholdMutation() {
  return Urql.useMutation<UpdateMinimumThresholdMutation, UpdateMinimumThresholdMutationVariables>(UpdateMinimumThresholdDocument);
};
export const ApproveTransactionDocument = gql`
    mutation approveTransaction($transactionId: Int!) {
  approveTransaction(transactionId: $transactionId)
}
    `;

export function useApproveTransactionMutation() {
  return Urql.useMutation<ApproveTransactionMutation, ApproveTransactionMutationVariables>(ApproveTransactionDocument);
};
export const RetryTransactionDocument = gql`
    mutation retryTransaction($transactionId: Int!) {
  retryTransaction(transactionId: $transactionId)
}
    `;

export function useRetryTransactionMutation() {
  return Urql.useMutation<RetryTransactionMutation, RetryTransactionMutationVariables>(RetryTransactionDocument);
};


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Asset: ResolverTypeWrapper<Asset>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Chain: Chain;
  Customer: ResolverTypeWrapper<Customer>;
  CustomerTransactionType: CustomerTransactionType;
  CustomerTransactions: ResolverTypeWrapper<CustomerTransactions>;
  CustomerTransactionsPagination: ResolverTypeWrapper<CustomerTransactionsPagination>;
  CustomerWallet: ResolverTypeWrapper<CustomerWallet>;
  CustomerWithdrawalPagination: ResolverTypeWrapper<CustomerWithdrawalPagination>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GasTransaction: ResolverTypeWrapper<GasTransaction>;
  GasTransactionType: GasTransactionType;
  GasTransactionsPagination: ResolverTypeWrapper<GasTransactionsPagination>;
  GasWallet: ResolverTypeWrapper<GasWallet>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  InternalTransferThreshold: ResolverTypeWrapper<InternalTransferThreshold>;
  MainWalletFailedTransaction: ResolverTypeWrapper<MainWalletFailedTransaction>;
  MainWalletTransaction: ResolverTypeWrapper<MainWalletTransaction>;
  MainWalletTransactionType: MainWalletTransactionType;
  MainWalletTransactionsPagination: ResolverTypeWrapper<MainWalletTransactionsPagination>;
  Mint: ResolverTypeWrapper<Mint>;
  Mutation: ResolverTypeWrapper<{}>;
  Organization: ResolverTypeWrapper<Organization>;
  OrganizationWallet: ResolverTypeWrapper<OrganizationWallet>;
  Query: ResolverTypeWrapper<{}>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  TransactionStatus: TransactionStatus;
  User: ResolverTypeWrapper<User>;
  Whitelist: ResolverTypeWrapper<Whitelist>;
  WithdrawThreshold: ResolverTypeWrapper<WithdrawThreshold>;
  getUsersOfOrganization: ResolverTypeWrapper<GetUsersOfOrganization>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Asset: Asset;
  Boolean: Scalars['Boolean']['output'];
  Customer: Customer;
  CustomerTransactions: CustomerTransactions;
  CustomerTransactionsPagination: CustomerTransactionsPagination;
  CustomerWallet: CustomerWallet;
  CustomerWithdrawalPagination: CustomerWithdrawalPagination;
  Float: Scalars['Float']['output'];
  GasTransaction: GasTransaction;
  GasTransactionsPagination: GasTransactionsPagination;
  GasWallet: GasWallet;
  Int: Scalars['Int']['output'];
  InternalTransferThreshold: InternalTransferThreshold;
  MainWalletFailedTransaction: MainWalletFailedTransaction;
  MainWalletTransaction: MainWalletTransaction;
  MainWalletTransactionsPagination: MainWalletTransactionsPagination;
  Mint: Mint;
  Mutation: {};
  Organization: Organization;
  OrganizationWallet: OrganizationWallet;
  Query: {};
  String: Scalars['String']['output'];
  User: User;
  Whitelist: Whitelist;
  WithdrawThreshold: WithdrawThreshold;
  getUsersOfOrganization: GetUsersOfOrganization;
};

export type AssetResolvers<ContextType = any, ParentType extends ResolversParentTypes['Asset'] = ResolversParentTypes['Asset']> = {
  balance?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mint?: Resolver<ResolversTypes['Mint'], ParentType, ContextType>;
  unconfirmedBalance?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  usdBalance?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Customer'] = ResolversParentTypes['Customer']> = {
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  organizationExternalId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerTransactionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerTransactions'] = ResolversParentTypes['CustomerTransactions']> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  confirmedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  customerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fromAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mint?: Resolver<ResolversTypes['Mint'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TransactionStatus'], ParentType, ContextType>;
  toAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  txHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CustomerTransactionType'], ParentType, ContextType>;
  uiAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  usdAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerTransactionsPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerTransactionsPagination'] = ResolversParentTypes['CustomerTransactionsPagination']> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transactions?: Resolver<Array<ResolversTypes['CustomerTransactions']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerWalletResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerWallet'] = ResolversParentTypes['CustomerWallet']> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assets?: Resolver<Array<ResolversTypes['Asset']>, ParentType, ContextType>;
  chain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalBalanceUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CustomerWithdrawalPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerWithdrawalPagination'] = ResolversParentTypes['CustomerWithdrawalPagination']> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  withdrawals?: Resolver<Array<ResolversTypes['MainWalletTransaction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GasTransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['GasTransaction'] = ResolversParentTypes['GasTransaction']> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  confirmedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fromAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gasWalletId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mint?: Resolver<ResolversTypes['Mint'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TransactionStatus'], ParentType, ContextType>;
  toAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  txHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['GasTransactionType'], ParentType, ContextType>;
  uiAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  usdAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GasTransactionsPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['GasTransactionsPagination'] = ResolversParentTypes['GasTransactionsPagination']> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transactions?: Resolver<Array<ResolversTypes['GasTransaction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GasWalletResolvers<ContextType = any, ParentType extends ResolversParentTypes['GasWallet'] = ResolversParentTypes['GasWallet']> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InternalTransferThresholdResolvers<ContextType = any, ParentType extends ResolversParentTypes['InternalTransferThreshold'] = ResolversParentTypes['InternalTransferThreshold']> = {
  amountInUSD?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  chain?: Resolver<Maybe<ResolversTypes['Chain']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MainWalletFailedTransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MainWalletFailedTransaction'] = ResolversParentTypes['MainWalletFailedTransaction']> = {
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  retriedTransactionId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MainWalletTransactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['MainWalletTransaction'] = ResolversParentTypes['MainWalletTransaction']> = {
  amount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  confirmedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  failedReason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fromAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mainWalletId?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  mint?: Resolver<ResolversTypes['Mint'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TransactionStatus'], ParentType, ContextType>;
  toAddress?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  transactionReference?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  txHash?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['MainWalletTransactionType'], ParentType, ContextType>;
  uiAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  usdAmount?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MainWalletTransactionsPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['MainWalletTransactionsPagination'] = ResolversParentTypes['MainWalletTransactionsPagination']> = {
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  transactions?: Resolver<Array<ResolversTypes['MainWalletTransaction']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MintResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mint'] = ResolversParentTypes['Mint']> = {
  address?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  chain?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  decimals?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  img?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isNative?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  symbol?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  usdPrice?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  approveTransaction?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationApproveTransactionArgs, 'transactionId'>>;
  logout?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  requestTwoFactorAuth?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  resetMyPassword?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  retryTransaction?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationRetryTransactionArgs, 'transactionId'>>;
  rollApiKey?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationRollApiKeyArgs, 'organizationId'>>;
  setupTwoFactorAuth?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationSetupTwoFactorAuthArgs, 'secret'>>;
  updateEmail?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateEmailArgs, 'email'>>;
  updateMinimumThreshold?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateMinimumThresholdArgs, 'amountInUSD' | 'chain' | 'organizationId'>>;
  updatePassword?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdatePasswordArgs, 'newPassword' | 'oldPassword'>>;
  updateUsername?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUsernameArgs, 'username'>>;
  withdraw?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationWithdrawArgs, 'amount' | 'mintId' | 'whitelistId'>>;
};

export type OrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  internalTransferThreshold?: Resolver<Array<ResolversTypes['InternalTransferThreshold']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  webhookAppId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  whitelist?: Resolver<Array<ResolversTypes['Whitelist']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganizationWalletResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganizationWallet'] = ResolversParentTypes['OrganizationWallet']> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  assets?: Resolver<Array<ResolversTypes['Asset']>, ParentType, ContextType>;
  chain?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalBalanceUSD?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  getCustomer?: Resolver<ResolversTypes['Customer'], ParentType, ContextType, RequireFields<QueryGetCustomerArgs, 'customerId'>>;
  getCustomerTransactions?: Resolver<ResolversTypes['CustomerTransactionsPagination'], ParentType, ContextType, RequireFields<QueryGetCustomerTransactionsArgs, 'customerId' | 'limit' | 'organizationId' | 'page'>>;
  getCustomerWallets?: Resolver<Maybe<Array<ResolversTypes['CustomerWallet']>>, ParentType, ContextType, RequireFields<QueryGetCustomerWalletsArgs, 'customerId'>>;
  getCustomerWithdrawals?: Resolver<ResolversTypes['CustomerWithdrawalPagination'], ParentType, ContextType, RequireFields<QueryGetCustomerWithdrawalsArgs, 'customerId' | 'limit' | 'organizationId' | 'page'>>;
  getCustomers?: Resolver<Array<ResolversTypes['Customer']>, ParentType, ContextType, RequireFields<QueryGetCustomersArgs, 'organizationId'>>;
  getEthGasOwed?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetEthGasOwedArgs, 'organizationId'>>;
  getGasTransactions?: Resolver<ResolversTypes['GasTransactionsPagination'], ParentType, ContextType, RequireFields<QueryGetGasTransactionsArgs, 'limit' | 'organizationId' | 'page'>>;
  getGasWallets?: Resolver<Array<ResolversTypes['GasWallet']>, ParentType, ContextType, RequireFields<QueryGetGasWalletsArgs, 'organizationId'>>;
  getMainWalletTransactions?: Resolver<ResolversTypes['MainWalletTransactionsPagination'], ParentType, ContextType, RequireFields<QueryGetMainWalletTransactionsArgs, 'limit' | 'organizationId' | 'page'>>;
  getMints?: Resolver<Array<ResolversTypes['Mint']>, ParentType, ContextType>;
  getOrganization?: Resolver<ResolversTypes['Organization'], ParentType, ContextType, RequireFields<QueryGetOrganizationArgs, 'organizationId'>>;
  getTransactionsToApprove?: Resolver<ResolversTypes['MainWalletTransactionsPagination'], ParentType, ContextType, RequireFields<QueryGetTransactionsToApproveArgs, 'limit' | 'organizationId' | 'page'>>;
  getUser?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  getUsersOfOrganization?: Resolver<ResolversTypes['getUsersOfOrganization'], ParentType, ContextType, RequireFields<QueryGetUsersOfOrganizationArgs, 'limit' | 'organizationId'>>;
  getWallets?: Resolver<Array<ResolversTypes['OrganizationWallet']>, ParentType, ContextType, RequireFields<QueryGetWalletsArgs, 'organizationId'>>;
  getWebhookIFrameUrl?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<QueryGetWebhookIFrameUrlArgs, 'organizationId'>>;
  getWhitelist?: Resolver<Array<ResolversTypes['Whitelist']>, ParentType, ContextType, RequireFields<QueryGetWhitelistArgs, 'organizationId'>>;
  getWithdrawThreshold?: Resolver<ResolversTypes['WithdrawThreshold'], ParentType, ContextType, RequireFields<QueryGetWithdrawThresholdArgs, 'organizationId'>>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  has2fa?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  organizationId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  username?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WhitelistResolvers<ContextType = any, ParentType extends ResolversParentTypes['Whitelist'] = ResolversParentTypes['Whitelist']> = {
  address?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  chain?: Resolver<ResolversTypes['Chain'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WithdrawThresholdResolvers<ContextType = any, ParentType extends ResolversParentTypes['WithdrawThreshold'] = ResolversParentTypes['WithdrawThreshold']> = {
  customers?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  main?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GetUsersOfOrganizationResolvers<ContextType = any, ParentType extends ResolversParentTypes['getUsersOfOrganization'] = ResolversParentTypes['getUsersOfOrganization']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Asset?: AssetResolvers<ContextType>;
  Customer?: CustomerResolvers<ContextType>;
  CustomerTransactions?: CustomerTransactionsResolvers<ContextType>;
  CustomerTransactionsPagination?: CustomerTransactionsPaginationResolvers<ContextType>;
  CustomerWallet?: CustomerWalletResolvers<ContextType>;
  CustomerWithdrawalPagination?: CustomerWithdrawalPaginationResolvers<ContextType>;
  GasTransaction?: GasTransactionResolvers<ContextType>;
  GasTransactionsPagination?: GasTransactionsPaginationResolvers<ContextType>;
  GasWallet?: GasWalletResolvers<ContextType>;
  InternalTransferThreshold?: InternalTransferThresholdResolvers<ContextType>;
  MainWalletFailedTransaction?: MainWalletFailedTransactionResolvers<ContextType>;
  MainWalletTransaction?: MainWalletTransactionResolvers<ContextType>;
  MainWalletTransactionsPagination?: MainWalletTransactionsPaginationResolvers<ContextType>;
  Mint?: MintResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  OrganizationWallet?: OrganizationWalletResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  Whitelist?: WhitelistResolvers<ContextType>;
  WithdrawThreshold?: WithdrawThresholdResolvers<ContextType>;
  getUsersOfOrganization?: GetUsersOfOrganizationResolvers<ContextType>;
};

